:root {
    --unit: 16px;
    --unit-sm: calc(var(--unit) / 2);
    --unit-lg: calc(var(--unit) * 2);

    --color-primary-dark: #212121;
    --color-secondary-dark: #888888;
    --color-primary-light: #fefefe;
    --color-accent: orange;
    --color-divider: #aaaaaa;
    --color-background-light: #dddddd;

    --font-size-medium-large: 1.1rem;
    --font-size-large: 1.2rem;
    --font-size-header: 2rem;
}

* {
    box-sizing: border-box;
}

*:focus {
    outline: 4px solid var(--color-primary-light);
    border-radius: 2px;
}

body {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    color: var(--color-primary-dark);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#root {
    width: 100vw;
    height: 100vh;
}

@media (prefers-color-scheme: dark) {
    body {
        background-color: var(--color-primary-dark);
        color: var(--color-primary-light);
    }
}
