.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--unit) 0;
}

.title {
    margin: 0;
    margin-right: var(--unit);
    font-size: var(--font-size-header);
    font-weight: 600;
    color: var(--color-accent);
}

.title a {
    color: var(--color-accent);
}
