.applicantTable {
    display: block;
    width: 100%;
    height: 100%;
}

.tbody {
    display: block;
    height: calc(100vh - 200px);
    overflow-y: auto;
}

.applicantTableRow {
    display: grid;
    grid-template-columns: 2fr 2fr 3fr 1fr 1fr 2fr 2fr 2fr;
    gap: var(--unit);
}

.applicantTableRow th {
    text-align: left;
    border-bottom: 1px solid var(--color-accent);
    font-size: var(--font-size-large);
    margin-bottom: var(--unit);
    overflow: hidden;
    word-wrap: break-word;
    font-weight: normal;
}

.applicantTableRow th[data-can-sort="true"] {
    font-weight: bold;
    cursor: pointer;
}

.applicantTableRow th[data-can-sort="false"] {
    outline: none;
}

.applicantTableRow th[data-is-sort="true"] {
    color: var(--color-accent);
}

.applicantTableRow td {
    overflow: hidden;
}
