.appContainer {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: var(--unit);
    display: grid;
    grid-template-rows: auto 1fr;
}

.appErrorContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
